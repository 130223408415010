@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@100&family=Sarabun:wght@100&family=Sriracha&family=Srisakdi&display=swap);
/*body {*/
/*margin: 0;*/
/*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*sans-serif;*/
/*-webkit-font-smoothing: antialiased;*/
/*-moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*monospace;*/
/*}*/
/*@import url('https://fonts.googleapis.com/css?family=Kanit&display=swap');*/
html,body{
    font-family: 'Kanit', sans-serif;
}
:focus {
    outline: -webkit-focus-ring-color auto 0!important;
}
input,
select,
button,
textarea,
.ui.button,
h1,
h2,
.ui.header{
    font-family: 'Kanit', sans-serif !important;
}
#edit_container .sidebar-padded .link {
    color: #000
}

#root {
    height: 100%;
}

#container {
    display: grid;
    grid-template-columns: 25% 75%;
    height: 100%;
    overflow: hidden;
}
.icon-absolute{
    position: absolute;
}
.icon-absolute.top {
    top: 0;
}
.icon-absolute.right {
    right: 0;
}
.icon-absolute.left {
    left: 0;
}
.icon-absolute.bottom {
    bottom: 0;
}

.icon-absolute .icon-frame {
    background-color: green;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    line-height: 1.1;
    border: 2px solid #fff;
}
.icon-absolute .icon-frame > .icon,
.icon-absolute .icon-frame > .i{
    color: white;
    margin: 0;
    font-size: 12px;
}

header{
    display: flex;
    height: 65px;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
}

header .logo{
    font-weight: bold;
    font-size: 20px;
    color: #333;
    line-height: 1;
}

header .right.floated .ui.button{
    margin-left: 5px;
}

.menu-slider{
    /*padding: 0 15px;*/
}
.menu-slider .slick-slider{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0!important;
    height: 50px;
}
.menu-slider  .slick-next{
    margin-left: 25px!important;
}
.menu-slider .slick-prev{
    margin-right: 25px!important;
}
.menu-slider  .slick-prev,
.menu-slider .slick-next{
    top: 0 !important;
    transform: translate(0, 0)!important;
    left: 0!important;
    right: 0!important;
    position: relative!important;
}
.menu-slider a{
    font-size: 15px!important;
    color: rgba(0,0,0,0.5);
}
.menu-slider a.active,
.menu-slider a:hover{
    font-size: 15px!important;
    color: rgba(0,0,0,0.9);
}

#container > div {
    overflow-x: auto;
}

#edit_container {
    /*padding: 1rem;*/
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

.edit-card-col {
    display: flex;
}

.edit-card-col .details {
    padding: 1rem;
}

.trash-link{
    background: none!important;
    color: #000!important;
    transition: all 0.3s!important;
}
.trash-link:hover{
    color: rgba(0,0,0,0.8)!important;
}

.priority-icon {
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*border-left: 1px solid #ccc;*/
}

.trash-icon {
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*border-left: 1px solid #ccc;*/
}

.priority-icon i {
    margin: 0 !important;
    font-size: 1.5em;
    width: auto !important;
}

.priority-icon i:active {
    opacity: 0.7;
}

.priority-icon button {
    width: 100%;
    padding: 0.5rem !important;
    margin: 0 !important;
}

.priority-icon button:first-child {
    border-radius: 0 3px 0 0;
    /*border-bottom: 1px solid #ccc;*/
}

.priority-icon button:last-child {
    border-radius: 0 0 3px 0;
}

.details {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.sidebar-padded {
    padding: 1rem;
}

#edit_container .sidebar-padded:first-child,
#edit_container .sidebar-padded:last-child {
    padding-top: 1rem
}

#edit_container .sidebar-padded {
    padding-top: 0;
    padding-bottom: 1rem
}

.edit-title {
    padding: 1rem;
    /*color: #fff;*/
    color: #000;
    /*background: #1B1C1D;*/
    background: #cccccc;
    font-weight: bold;
    margin-bottom: 15px;
}
.edit-title .breadcrumb div,
.edit-title .breadcrumb a{
    color: rgba(0,0,0,0.8)!important;
}
.edit-title .breadcrumb a:hover{
    color: rgba(0,0,0,1)!important;
    text-decoration: none!important;
}

.content-padded {
    padding: 15px 0;
}

.image_frame {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.close_btn_pos {
    position: absolute;
    top: 0;
    right: 0;
}

.image_frame img {
    max-width: 100%;
}

.medium-zoom-image--opened {
    z-index: 1;
}

.image_content_frame img {
    max-width: 100%;
}

.flex {
    display: flex;
}

.ui.card.link:hover {
    color: #333333 !important;
    text-decoration: none !important;
}

.medium-zoom--opened .medium-zoom-overlay {
    z-index: 1000;
}

img.medium-zoom-image--opened {
    z-index: 1001 !important;
}

.menu-slider .slick-track > div {
    margin-left: 24px;
}

.slick-prev:before, .slick-next:before {
    color: #000 !important;
    font-size: 20px !important;
}

.slick-next {
    right: -20px !important;
}

.slick-prev {
    left: -20px !important;
}

.slick-next,
.slick-prev {
    z-index: 1;
}

.card.active,
.card.active:hover {
    border: 2px solid #2185d0 !important;
}
.slick-slider {
    margin-top: -0.5em!important;
}
.slick-slider .slick-list {
    margin: 0 -10px;
}
.color-box {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0.8;
}
.color-box > div {
    margin-top: 5px;
    display: inline-flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 5px;
    cursor: pointer;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}
.color-box > div i{
    opacity: 0;
}
.color-box > div:hover i{
    opacity: 1;
}

.color-box > div:first-child {
    margin-left: 0;
}

.color-box > div:hover,
.color-box > div.selected,
.clip_art_image_frame:active {
    border: 2px solid #2185d0 !important;
}

.clip_art_image_frame:hover {
    border: 2px solid #999;
}

.custom-ui .ui.card > .content,
.custom-ui .ui.cards > .card > .content {
    padding: 1rem 0.8rem;
}

.total-price {
    margin-top: 15px !important;
}

.total-price strong {
    font-size: 25px;
}

.total-price span {
    margin-right: 10px;
}

.clip_art_image_frame {
    width: 100%;
    height: 150px;
    background: #cccccc;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.clip_art_image_frame img {
    max-height: 100%;
}

.input-color .ui.dropdown{
    border-top-right-radius: 6px!important;
    border-bottom-right-radius: 6px!important;
}
.input-color .ui.button {
    border-bottom-right-radius: 0!important;
    border-top-right-radius: 0!important;
    border-right: 0!important;
}
.input-color select{
    border-bottom-left-radius: 0!important;
    border-top-left-radius: 0!important;
}
.table-middle.table-space-between h1,
.table-middle.table-space-between h2,
.table-middle.table-space-between h3{
    margin: 0!important;
}
.table-middle {
    display: flex;
    align-items: center;
}
.table-space-between{
    justify-content: space-between;
}
.table-link{
    cursor: pointer;
    flex-grow: 1;
}
.folder.open.icon,
.table-link:hover .folder.icon{
    display: none;
}
.table-link:hover .folder.open.icon,
.folder.icon{
    display: inline;
}
.login-bg{
    background-color: #DADADA;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 415px;
}
.login-bg .ui.grid{
    min-width: 400px;
    position: relative;
    top: -90px;
}
.slick-next:before{
    /*content: "\f0da"!important;*/
    content: "\f105"!important;
}
.slick-prev:before{
    /*content: "\f0d9"!important;*/
    content: "\f104"!important;
}
.slick-next:before,
.slick-prev:before{
    color: rgba(0,0,0,0.5)!important;
    font-family: 'Icons', serif !important;

}
.slick-next,
.slick-prev{
    margin-top: 3px;
}
.list_card img {
    max-width: 100%;
}
.row.padded.list_card{
    margin-right:-15px!important;
    margin-left:-15px!important;
}
.row.padded.list_card .column.padded{
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
}
.list_card .title{
    font-weight: bold;
    font-size: 16px;
}
.list_card .date{
    font-size: 14px;
    color: rgba(0,0,0,0.5);
}
.list_img{
    background: #000;
}
.list_card .list_details{
    padding-right: 10px;
}
.page-component-scroll{
    flex-grow: 1;
}
.ui.header .icon:only-child{
    padding: 0;
}
.ui.header .ui.button{
    padding: .78571429em .78571429em .78571429em;
}
.shirt_slide_list{
    padding: 10px;
}
.toggle-opacity{
    position: absolute;
    right: 5px;
    top: 5px;
    z-index:10;
}

#content {
    position: relative;
}
.ui.link.card>.image{
    height: 130px;
    overflow: hidden;
}
.ui.link.card>.image img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin:auto;
}
#color-picker,
#text-color-menu{
    padding: 0!important;
    margin: 0!important;
}
#color-picker .sketch-picker {
    box-shadow: none!important;
}
#text-color-menu .ui.button{
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 6px!important;
    border-bottom-left-radius: 6px!important;
}
.preview-image img{
    max-width: 100%;
}
.preview-image {
    margin: 0!important;
    overflow: hidden;
    min-height: 430px;
}
.custom-ul > li {
    margin-top: 5px;
}
.custom-ul > li:first-child {
    margin-top: 0px;
}
.slick-dots{
    position: relative!important;
    bottom: 0!important;
}
.resp-design-menu-btn {
    display: none;
    margin-bottom: 1em;
}
.actions_btn.hide,
.resp-show {
    display: none!important;
}
.fabric_texture.image {
    height: 200px!important;
}

@media (max-width: 720px) {
    .resp-hide {
        display: none!important;
    }
    .resp-show,
    .resp-design-menu{
        display: block!important;
    }
    .resp-design-menu .button{
        width: 100% !important;
        display: block!important;
    }
    .resp-design-menu{
        flex-direction: column;
    }
    .resp-design-menu{
        margin-top: 1em!important;
        display: none!important;
    }
}
@media (max-width: 991px) {
    /*.resp-flex-reverse {*/
    /*    flex-direction: column-reverse!important;*/
    /*}*/
    /*.row[class*="two column tablet"] > .column {*/
    /*    width: 50%;*/
    /*}*/
    .custom-ui {
        margin-bottom: 1em;
    }
    .buttons.resp-button .button{
        padding-left: 5px;
        padding-right: 5px;
    }
    .preview-image {
        height: 400px;
    }
    .resp-design-menu-btn,
    .resp-design-menu.active{
        display: block!important;
    }
    .menu-button,
    .menu-button button{
        border-top-right-radius: 0!important;
        border-top-left-radius: 0!important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    /*Tablet*/
    .preview-image {
        height: 560px;
    }
    .canvas-container {
        width: 425px!important;
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    /*Small Monitor*/
    .custom-ui {
        margin-bottom: 1em;
    }
    .resp-design-menu .button,
    .buttons.resp-button .button{
        padding-left: 5px;
        padding-right: 5px;
    }
    .preview-image {
        height: 550px;
    }

    .resp-design-menu-btn,
    .resp-design-menu.active{
        display: block!important;
    }
}

